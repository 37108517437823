import { Card, CardContent, CardHeader, CardTitle } from '@repo/ui/components/Card.js'
import { Icon } from '@repo/ui/components/Icon.js'
import type { IconName } from '@repo/ui/icons/icons'

export const Statistic = ({
  label,
  icon,
  value
}: {
  label: string
  icon: IconName
  value: number | string
}) => {
  return (
    <Card>
      <CardHeader className="px-4 py-2 pb-0 flex flex-row items-center justify-between text-gray-500">
        <CardTitle className="text-xxs font-bold tracking-widest uppercase">{label}</CardTitle>
        <Icon name={icon} size="md" className="opacity-50" />
      </CardHeader>
      <CardContent className="pb-2 px-4 pt-0.5">
        <span className="text-2xl font-extrabold" data-slot="value">
          {value}
        </span>
      </CardContent>
    </Card>
  )
}
